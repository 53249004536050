<template>
  <el-dialog :title="title" width="620px" :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
    <el-form size="small" :model="passwordForm" ref="passwordForm" label-width="130px">
      <el-form-item label="请输入新密码：" prop="password"
                    :rules="[{ required: true, message: '请输入新密码', trigger: 'blur' },{validator:validator.isPwd, trigger:'blur'}]">
        <el-input v-model="passwordForm.password"
                  placeholder="请输入新密码（8~16位数字、特殊字符、大写字母、小写字母组合）"
                  maxlength="16" :type="passwordShow ? 'password' : 'text'"
                  auto-complete="new-password">
          <template slot="suffix">
            <div v-show="passwordShow" @click="passwordShow = !passwordShow"
                 class="iconfont icon-eye-close passwordShowIcon"></div>
            <div v-show="!passwordShow" @click="passwordShow = !passwordShow"
                 class="iconfont icon-browse passwordShowIcon"></div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="confirmPassword" :rules="rule">
        <el-input v-model="passwordForm.confirmPassword"
                  placeholder="请确认新密码（8~16位数字、特殊字符、大写字母、小写字母组合）"
                  :type="passwordShowSure ? 'password' : 'text'"
                  maxlength="16" auto-complete="new-password">
          <template slot="suffix">
            <div v-show="passwordShowSure" @click="passwordShowSure = !passwordShowSure"
                 class="iconfont icon-eye-close passwordShowIcon"></div>
            <div v-show="!passwordShowSure" @click="passwordShowSure = !passwordShowSure"
                 class="iconfont icon-browse passwordShowIcon"></div>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="text_center">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()"
                 v-noMoreClick>提交
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    // 自定义校验
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.passwordForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      title: '修改密码',
      visible: false,
      passwordForm: {
        password: '',
        confirmPassword: '',
      },
      selectData: {},
      rule: [{validator: validatePass2, trigger: 'blur'}, {required: true, message: '请确认新密码', trigger: 'blur'}],
      passwordShow: true,
      passwordShowSure: true,
    }
  },

  methods: {
    init(row) {
      this.passwordForm.password = ''
      this.passwordForm.confirmPassword = ''
      this.selectData = row
      this.visible = true
      this.passwordShow = true
      this.passwordShowSure = true
    },

    doSubmit() {
      this.$refs['passwordForm'].validate((valid) => {
        if (valid) {
          this.$axios(this.api.auth.updateSysUserPassword,{
            ...this.passwordForm,
            mobilePhone:this.selectData.mobilePhone,
          },'post').then(res => {
            if (res.status) {
              this.$message.success('密码修改成功！')
              this.$emit('refreshDataList')
              this.visible = false
            } else {
              this.$message.error('密码修改失败，请重试！')
            }
          })
        }
      })
    },
  },
}
</script>